<template>
  <div class="appointment-container">
    <el-table :data="tableData" style="width: 100%">
      <el-table-column type="index" label="序号" width="180">
      </el-table-column>
      <el-table-column prop="createTime" label="预约时间" width="180">
      </el-table-column>
      <el-table-column prop="name" label="姓名">
      </el-table-column>
      <el-table-column prop="phoneNumber" label="电话">
      </el-table-column>
      <el-table-column prop="companyName" label="企业名">
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import { Message } from 'element-ui'
import { appointment, getAppointmentList } from '../../api.ts'
export default {
  name: 'Appointment',
  // components: {
  //   Table,
  //   TableColumn
  // },
  data() {
    return {
      hasMounted: false,
      resolveFn: null,
      tableData: []
    }
  },
  methods: {},
  created() {
    getAppointmentList().then(res => {
      this.$data.tableData = res.reverse()

    })
  }
}

</script>
<style lang="scss" scoped>
.appointment-container {}

</style>
